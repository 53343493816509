import React, { Component } from 'react'

import Img from 'gatsby-image'

import './style.scss'

export default class Authored extends Component {
  render() {
    const { author, avatar, date, mini } = this.props
    return (
      <div
        className={`c-authored  align-center font-italic  ${
          mini ? 'c-authored--mini' : 'mb-2 d-flex'
        }`}
      >
        {!mini && (
          <div className="c-authored__avatar mr-1">
            <Img fluid={avatar.childImageSharp.fluid} />
          </div>
        )}
        <span
          className={`c-author__name text-secondary ${
            !mini ? 'mr-1' : 'd-block'
          }`}
        >
          {author},
        </span>
        <span className={`c-author__date ${!mini ? '' : 'd-block'}`}>
          posted on {date}
        </span>
      </div>
    )
  }
}
