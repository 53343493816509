import React from 'react'
import { graphql } from 'gatsby'
import { get } from 'lodash'
import { MDXProvider } from '@mdx-js/react'
import MDXRenderer from 'gatsby-plugin-mdx/mdx-renderer'

import CTABlock from '../components/CTABlock'
import Tags from '../components/Tags'
import LogoList from '../components/LogoList'
import FAQ from '../components/FAQ'
import HeroSingle from '../components/HeroSingle'
import ContactCTA from '../components/ContactCTA'
import LegalDisclaimer from '../components/LegalDisclaimer'
import Layout from '../components/Layout'
import MassiveTitle from '../components/MassiveTitle/index'
import SEO from '../components/SEO/index'
import Authored from '../components/Authored'
import CTABanner from '../components/CTABanner'



import MailChimpDialog from '../components/MailChimpDialog'
import SectionHeader from '../components/SectionHeader'
import Video from '../components/Video'
import SkewedBackground from '../components/SkewedBackground'
import BlogNav from '../components/BlogNav'

import ProductOverviewHero from '../components/ProductOverviewHero'
import PageNav from '../components/PageNav'
import StepSlider from '../components/StepSlider'
import ButtonGroup from '../components/ButtonGroup'
import Breakout from '../components/Breakout'
import ProductTeasers from '../components/ProductTeasers'
import TargetTeasers from '../components/TargetTeasers'
import MiniQuote from '../components/MiniQuote'

const ModularPage = ({ data, location }) => {
  const intro = get(
    data,
    'page.frontmatter.intro.childMarkdownRemark.html',
    null
  )

  const {
    type,
    tags,
    date,
    author,
    author_avatar: avatar,
  } = data.page.frontmatter

  let pageType = ''
  if (type === 'application') {
    pageType = 'Applications'
  }
  if (type === 'learn') {
    pageType = 'Learn'
  }
  if (type === 'blog') {
    pageType = 'Blog'
  }
  return (
    <Layout location={location}>
      <SEO path={location.pathname} frontmatter={data.page.frontmatter} />
      <article>
        {type === 'product_overview' ? (
          <ProductOverviewHero
            title={data.page.frontmatter.title}
            introTitle={data.page.frontmatter.intro_title}
            body={data.page.frontmatter.intro_text}
            buttons={data.page.frontmatter.buttons}
            image={data.page.frontmatter.image}
          />
        ) : (
          <>
            <div className="position-relative">
              <HeroSingle data={data.page} />
              <CTABlock />
            </div>
            {pageType.length > 0 && <MassiveTitle>{pageType}</MassiveTitle>}
          </>
        )}

        {(intro || tags) && (
          <header className="container u-hard-pull-up position-relative">
            <div className=" bg-offwhite p-2 p-sm-5 c-bg-expand-right">

              {intro && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: intro,
                  }}
                />
              )}
              {date && <Authored author={author} avatar={avatar} date={date} />}
              {tags && (
                <div className="mt-3">
                  <Tags data={tags} />
                </div>
              )}
            </div>
          </header>
        )}
        <div className="mini-container my-2 my-md-4 my-lg-5 body">
          <MDXProvider
            components={{
              SectionHeader,
              Video,
              SkewedBackground,
              BlogNav,
              PageNav,
              StepSlider,
              ButtonGroup,
              Breakout,
              ProductTeasers,
              TargetTeasers,
              MiniQuote,
            }}
          >
            <MDXRenderer>{data.page.body}</MDXRenderer>
          </MDXProvider>
        </div>

        {!type === 'product_overview' &&
        data.faqQuestions &&
        data.faqQuestions.edges.length ? (
          <FAQ
            title={data.page.frontmatter.title}
            categories={data.faqCategories}
            questions={data.faqQuestions}
          />
        ) : (
          ''
        )}
      </article>
      <div
        className={
          type === 'product_overview'
            ? 'bg-white position-relative pt-2 pt-md-3 pt-lg-5'
            : ''
        }
        style={{ zIndex: 50 }}
      >
        <ContactCTA  data={data.page.frontmatter.contact_cta} />
        <LegalDisclaimer data={data.page.frontmatter.legal_disclaimer} />
        <LogoList />
        <div className="bg-light c-footer">
          <CTABanner data={data.page.frontmatter.cta_banner} />
        </div>
      </div>

    </Layout>
  )
}

export default ModularPage

export const pageQuery = graphql`
  query($slug: String!) {
    page: mdx(fields: { slug: { eq: $slug } }) {
      ...SEOMDX
      ...HeroSingleMDX
      ...ctaBannerMDX
      ...contactCTAMDX
      ...legalDisclaimerMDX
      frontmatter {
        tags
        intro_title
        title
        intro_text
        image {
          childImageSharp {
            original {
              src
            }
          }
        }
        buttons {
          label
          link
          external
        }
        date(formatString: "Do MMMM YYYY")
        author
        author_avatar {
          childImageSharp {
            fluid(maxWidth: 150, maxHeight: 150) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        type
        intro {
          childMarkdownRemark {
            html
          }
        }
      }
      body
    }

    faqQuestions: allMarkdownRemark(
      filter: {
        frontmatter: { title: { ne: "" } }
        fileAbsolutePath: { regex: "/faq/" }
        fields: { slug: { regex: $slug } }
      }
    ) {
      edges {
        ...FAQ
      }
    }

    faqCategories: allFaqCategoriesYaml {
      edges {
        node {
          id
          title
          description
          icon {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
      }
    }
  }
`
